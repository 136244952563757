import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Modal from "../components/modal"

import Expert from "../images/expertise.png"
// import EdwardBrittany from "../images/brittany-edward.png"

const AboutUs = ({ data }) => {
	console.log(data)
	return (
		<Layout>
			<SEO
				title="Leadership Development Program - Business Coaching Services"
				description="Schedule your complimentary coaching session with AGL & learn how our leadership development program & business coaching services will help foster a growth mindset in employees."
			/>
			<div className="page-headline">
				<div className="container">
					<div className="section-heading text-center">
						<h6 className="font-weight-bold text-uppercase flair">About Us</h6>
						<h1>
							<strong>BUILD A TEAM THAT SHOWS UP</strong>
						</h1>
					</div>
				</div>
				<div className="headline-bg" />
			</div>
			<div className="page-content">
				<div className="container">
					<strong>
						We love helping teams wake up and love Mondays. We want to help you
						build a team that shows up every day engaged, creative, and
						productive in their work. With Coaching for Good, you can
						confidently build a better, more effective team. A team who trusts
						their boss, loves their team, and is passionate about their work.
						The result is higher profit and greater impact to your stakeholders.
					</strong>
				</div>

				<section id="expertise">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-6 expert py-4">
								<div className="content-expert">
									<h1 className="text-center mb-3 pt-0">EXPERTISE</h1>
									<p>
										Each member of our team is highly trained through
										cutting-edge executive and leadership coaching programs,
										using neuroscience and positive psychology to effect
										positive change. We operate in alignment with the highest
										standards in the coaching industry with credentialing
										through the International Coaching Federation and our
										expertise covers a range of people-helping disciplines. We
										look forward to supporting your team!
									</p>
								</div>
							</div>
							<div className="col-md-6">
								<img className="img-fluid" src={Expert} alt="Expertise" />
							</div>
						</div>
					</div>
				</section>

				{/*<section id="ed-and-brit">
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<img
									className="img-fluid"
									src={EdwardBrittany}
									alt="Edward & Brittany"
								/>
							</div>
							<div className="col-md-6">
								<h3>EDWARD & BRITTANY MACDONALD MASTER CERTIFIED COACHES</h3>
								<p>
									Edward and Brittany have been empowering people to show up and
									love their lives throughout their entire careers. As business
									partners turned adventure couple, Monday’s are regularly their
									favorite day of the week.
								</p>
								<p>
									Edward brings his strategic vision and constant ideation to
									help the organization dream big in the position of CEO.
									Brittany thrives in the role of COO, where she is able to
									organize and manage details to her heart’s content. Together,
									they create a perfect storm of ideas and execution that serve
									their clients and launch them to exciting (and sometimes
									crazy!) personal goals.
								</p>
								<p>
									With a dozen credentials and certifications between them, they
									cover a range of interpersonal and leadership disciplines,
									from neuroscience work, emotional intelligence, risk
									avoidance, and more. Most significantly, both hold the Master
									Certified Coach Credential from the International Coach
									Federation (ICF), making them the youngest team of MCC coaches
									in the world.
								</p>
								<p>
									In addition, Edward is a Licensed NeuroPositive Coach and
									works with clients who are ready to break through the
									artificial ceilings that keep them stuck by rewiring their
									patterns of thinking, feeling, and acting to experiencing
									life-changing shifts. Brittany focuses her expertise on coach
									training and developing young leaders to clarify their vision
									and values and break through limiting beliefs to achieve the
									goals they could only begin to imagine.
								</p>
								<p>
									Together, they love discovering coffee shops, breweries, and
									bistros in cities and small towns around the globe, taking
									their work and their mindsets with them. Through AGL, they
									hope to facilitate a world where people can spend the better
									part of their waking life dedicated to work they love, a team
									they can trust, and a life that fulfills them.
								</p>
							</div>
						</div>
					</div>
				</section>*/}
				<hr className="w-50 mx-auto" />
				<section id="team-v2">
					<div className="container">
						<h1 className="text-center mb-5 pt-0">THE TEAM</h1>
						<div className="row">
							{data.allSanityTeam.edges.map(team => (
								<div className="col-md-4 text-center mb-5" key={team.node.id}>
									<img
										className="img-fluid"
										src={team.node.mainImage.asset.fluid.src}
										alt={team.node.title}
									/>
									<h5 className="mb-0 pb-2">{team.node.title}</h5>
									<p>
										<strong>{team.node.position}</strong>
									</p>
									<p>
										<strong>{team.node.level}</strong>
									</p>
									<p className="my-4">{team.node.body}</p>
									{team.node.title === "CARISSA GAY" ? (
										<div className="text-center">
											<a
												href="https://app.moonclerk.com/pay/44a7w80fa8ff"
												className="team-btn justify-content-center"
											>
												WORK WITH
											</a>
										</div>
									) : team.node.title === "JOSHUA SHI" ? (
										<div className="text-center">
											<a
												href="https://app.moonclerk.com/pay/8uwf20b86xg"
												className="team-btn justify-content-center"
											>
												WORK WITH
											</a>
										</div>
									) : team.node.title === "LAUREN REINS" ? (
										<div className="text-center">
											<a
												href="https://app.moonclerk.com/pay/6uqx8oaozkq9"
												className="team-btn justify-content-center"
											>
												WORK WITH
											</a>
										</div>
									) : null}
								</div>
							))}
						</div>
					</div>
				</section>
				{/*<section id="icf" className="pt-0">
					<div className="container">
						<div className="row">
							<div className="col-12 text-center">
								<h1>ICF CREDENTIALS</h1>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<p>
									<strong>What should you look in for in a coach?</strong>
								</p>
								<ul>
									<li>Formal training.</li>
									<li>ICF affiliation, membership, and/or certification.</li>
									<li>Authentic connection.</li>
									<li>Confidentiality.</li>
									<li>
										Opportunity to set and clarify your goals and desired
										outcomes.
									</li>
									<li>Measurable success.</li>
								</ul>
							</div>
							<div className="col-md-6">
								<p>
									<strong>Why ICF?</strong>
								</p>
								<ul>
									<li>
										The International Coach Federation is the premier
										international governing body for the coaching profession.
									</li>
									<li>
										ICF provides resources, networking, training, certification
										standards, continuing education, and standards of excellence
										for coaches around the world.
									</li>
								</ul>
							</div>
						</div>
						<hr className="w-50 mx-auto my-5" />
						<div className="row">
							<div className="col-md-9 mx-auto text-center">
								<h5>What is ICF Certification?</h5>
								<p>
									ICF certification holds coaches to a standard of excellence
									that consists of a 3-hour exam and varying levels of mentor
									coaching hours, accredited training programs, and minimum
									client hours.
								</p>
								<p>
									ICF offers three credentials: ACC – Associate Certified Coach,
									PCC – Professional Certified Coach, and MCC – Master Certified
									Coach.
								</p>
								<p>
									<a href="https://coachfederation.org/" target="_blank">
										Learn more.
									</a>
								</p>
								<p>
									Each successive level of certification corresponds to
									significant increase in training requirements and coach skill
									set.
								</p>
							</div>
						</div>
						<hr className="w-50 mx-auto my-5" />
						<div className="row text-center">
							<div className="col-md-12">
								<p className="font-weight-bold">
									What levels of coaching does AGL offer?
								</p>
								<p>
									<em>
										AGL offers coaching at all three levels of certification.
									</em>
								</p>
								<p className="font-weight-bold">
									<em>Our rates are as follows:</em>
								</p>
								<p>
									<em>$150/session (ACC) – ideal for students</em>
								</p>
								<p>
									<em>$200/session (PCC) – ideal for young professionals</em>
								</p>
								<p>
									<em>$300/session (MCC) – ideal for leaders and executives</em>
								</p>
							</div>
						</div>
					</div>
				</section>
				<section id="information" className="pt-0">
					<div className="container">
						<div className="row">
							<div className="col">
								<h4>CONTACT INFORMATION:</h4>
								<a href="mailto:impact@coachingforgood.org">
									impact@coachingforgood.org
								</a>
								<br />
								<a href="tel:7198002149">719-800-2149</a>
							</div>
						</div>
					</div>
				</section>*/}
			</div>
		</Layout>
	)
}
export default AboutUs

export const AboutUsPageQuery = graphql`
	query AboutUsPageQuery {
		allSanityTeam(
			sort: { order: DESC, fields: _updatedAt }
			filter: { id: { nin: ["840b4de5-0edf-5140-99a0-e6a9397584ff"] } }
		) {
			edges {
				node {
					id
					_updatedAt
					mainImage {
						asset {
							fluid {
								src
							}
						}
					}
					title
					position
					level
					slug {
						current
					}
					excerpt
					body
					booking_form_url
				}
			}
		}
	}
`
